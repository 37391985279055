import {
  ElMessage
} from 'element-plus'
import {
  forexOrders,
  findCurrencyType
} from './ForeignExchangeTransactionDetailsApi'
import dayjs from "dayjs";
import {
  ergodic
} from '../commonMethods'
import {
  thousandthConversion
} from '../commonMethods'
// 获取交易订单
export const details = async (data) => {
  let params = {
    orderStates: [3],
    acId: JSON.parse(sessionStorage.getItem('acid')),
    pageIndex: data.pageIndex,
    pageSize: data.pageSize
  }
  let res = await forexOrders(params)
  let newRes = res.data
  if (newRes.code == 200) {
    data.total = newRes.data.total
    newRes.data.forexOrderVOList.forEach((item) => {
      if (item.orderState == 3) {
        item.orderState = '交易完成'
      }

    })
    data.forexOrderVOList = newRes.data.forexOrderVOList
    data.forexOrderVOList.forEach((item) => {
      item.purchasingAmounts = thousandthConversion(item.purchasingAmount)
      item.createTime = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
      item.capitalBalance = item.buyerCurrency + ':' + item.buyerBalance + '/' + item.sellerCurrency + ':' + item.sellerBalance
    });
  }
}

//查询货币
export const findCurrency = async (data) => {
  let res = await findCurrencyType()
  let newRes = res.data
  if (newRes.code == 200) {
    data.currencyData = newRes.data.currencyData
  }
}

//查询历史 
export const historicalTimehistoricalTimes = async (data) => {
  let startTime = "";
  let endTime = "";
  if (data.historicalTime != null) {
    if (
      data.historicalTime[0] &&
      data.historicalTime[0] != null &&
      data.historicalTime[0] != undefined
    ) {
      startTime = data.historicalTime[0];
      endTime = data.historicalTime[1].substring(0, 11) + "23:59:59";
    }
  }
  let params = {
    acId: JSON.parse(sessionStorage.getItem('acid')),
    orderStates: [3],
    tradingDirection: data.accountType,
    startTime: startTime,
    endTime: endTime
  }
  let res = await forexOrders(params)
  let newRes = res.data
  if (newRes.code == 200) {
    newRes.data.forexOrderVOList.forEach(item => {
      if (item.orderState == 3) {
        item.orderState = "交易完成"
      }
      item.purchasingAmounts = thousandthConversion(item.purchasingAmount)
    })
    data.forexOrderVOList = newRes.data.forexOrderVOList
    data.total = newRes.data.total
    ergodic(data)
  }

}